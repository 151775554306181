import {logger} from "nx/src/utils/logger"

export function formatLocalDate(date: string | number): string {
  const theDate = new Date(date)
  return theDate ? theDate.toLocaleDateString() : date.toString()
}

export function convertToISODateString(date: Date): string {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-based
  const day = String(date.getDate()).padStart(2, '0')
  // logger.debug('format-local-date#convertToISODateString: date=', date, ', isoDate=', `${year}-${month}-${day}`)
  return `${year}-${month}-${day}`
}


export function formatToISODate(date: string | number): string {
  const theDate = new Date(date)
  if (!theDate) {
    return date.toString()
  }
  // return `${theDate.getFullYear()}-${theDate.getf}-${theDate.getDay()}`
  return theDate.toISOString().substring(0, 10)
}

export function formatToLocalISOString(date: Date): string {
  const offset = date.getTimezoneOffset()

  // Convert the offset to milliseconds and subtract it from the date
  const dateWithOffset = new Date(date.getTime() - offset * 60000)
  return dateWithOffset.toISOString()
}
